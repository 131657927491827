import { GatsbyBrowser } from "gatsby";

// Returns true if the new route should retain the scroll position of the previous route
const shouldUpdateScroll: GatsbyBrowser["shouldUpdateScroll"] = ({
  routerProps: { location },
}) => {
  // Whether the route is intended to be rendered as a modal
  const isModal = location.state?.modal;
  // Whether we should specifically prevent scrolling on the new route
  const preventUpdateScroll = location.state?.noScroll;

  return !isModal && !preventUpdateScroll;
};

export default shouldUpdateScroll;
