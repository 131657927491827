import React, { Ref } from "react";
import { ChangeHandler, FieldErrors } from "react-hook-form";

// This disable is reuqired because our version of eslint-plugin-react
// has a bug causing react/prop-types to error when using React.forwardRef.
// See https://github.com/yannickcr/eslint-plugin-react/issues/3140
/* eslint-disable react/prop-types */
const Input = React.forwardRef<
  HTMLElement,
  {
    name: string;
    type: "text" | "tel" | "email" | "checkbox" | "textarea";
    label: React.ReactNode;
    rows?: number;
    errors?: FieldErrors;
    placeholder?: string;
    onBlur: ChangeHandler;
    onChange: ChangeHandler;
    className?: string;
    labelClassName?: string;
    inputClassName?: string;
    errorClassName?: string;
  }
>(
  (
    {
      name,
      type,
      label,
      rows,
      errors,
      placeholder,
      onBlur,
      onChange,
      className,
      labelClassName,
      inputClassName,
      errorClassName,
    },
    ref
  ) => {
    const message = errors?.[name]?.message?.toString();

    const error = <p className={errorClassName}>{message}</p>;

    if (type === "checkbox") {
      return (
        <div className={className}>
          <label className={labelClassName}>
            <input
              ref={ref as unknown as Ref<HTMLInputElement>}
              name={name}
              id={`${name}Input`}
              type={type}
              onBlur={onBlur}
              onChange={onChange}
              className={inputClassName}
            />
            {label}
          </label>
          {error}
        </div>
      );
    }

    if (type === "textarea") {
      return (
        <div className={className}>
          <label className={labelClassName} htmlFor={`${name}Input`}>
            {label}
          </label>
          <textarea
            ref={ref as unknown as Ref<HTMLTextAreaElement>}
            name={name}
            id={`${name}Input`}
            onBlur={onBlur}
            onChange={onChange}
            placeholder={placeholder}
            rows={rows}
            className={inputClassName}
          />
          {error}
        </div>
      );
    }

    return (
      <div className={className}>
        <label className={labelClassName} htmlFor={`${name}Input`}>
          {label}
        </label>
        <input
          ref={ref as unknown as Ref<HTMLInputElement>}
          name={name}
          id={`${name}Input`}
          type={type}
          onBlur={onBlur}
          onChange={onChange}
          placeholder={placeholder}
          className={inputClassName}
        />
        {error}
      </div>
    );
  }
);

Input.displayName = "Input";

export default Input;
