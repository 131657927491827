import React from "react";
import * as Sentry from "@sentry/react";
import ErrorFallBack from "./ErrorFallback";

const ErrorBoundary: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const isBrowser = typeof window !== "undefined";

  if (isBrowser) {
    return (
      <Sentry.ErrorBoundary fallback={<ErrorFallBack />}>
        {children}
      </Sentry.ErrorBoundary>
    );
  }

  return <>{children}</>;
};

export default ErrorBoundary;
