import React from "react";
import { navigate } from "gatsby";

export const defaultValue = {
  /** true if the current route is a modal */
  isModal: false,
  /** true if both the previous and current route are modals */
  wasModal: false,
  /* A function that navigates back to the previous route and prevents scrolling */
  handleClose: (): void => {
    navigate("/", { state: { noScroll: true } });
  },
};

const ModalRoutingContext = React.createContext(defaultValue);

export default ModalRoutingContext;
