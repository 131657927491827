import React from "react";

import CenteredPageSection from "./CenteredPageSection";

import error_404_banner from "../images/404-banner.jpg";

const ErrorFallback: React.FC = () => {
  return (
    <>
      <div
        className={`w-full h-32 lg:h-80 flex flex-col justify-center align-middle text-center text-white bg-top bg-cover`}
        style={{ backgroundImage: `url(${error_404_banner}` }}
      >
        <h1>An error has occurred</h1>
      </div>
      <CenteredPageSection classNames="flex md:justify-center md:align-center">
        <div className="md:w-2/3">
          <p>We have encountered an error in this page.</p>
          <p>
            The team has been automatically alerted and will take a look. You
            can try using the search to find what you&apos;re looking for, or{" "}
            <a className="text-clhbid-orange" href="/contact">
              contact us
            </a>{" "}
            if need more assistance.
          </p>
          <p>Thanks!</p>
        </div>
      </CenteredPageSection>
    </>
  );
};

export default ErrorFallback;
