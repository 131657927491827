import React, { useEffect } from "react";
import Consent from "react-cookie-consent";

const CookieConsent: React.FC = () => {
  useEffect(() => {
    // https://developers.google.com/tag-platform/security/guides/consent?consentmode=advanced#default-consent
    window?.gtag?.("consent", "default", {
      ad_storage: "granted",
      ad_user_data: "granted",
      ad_personalization: "granted",
      analytics_storage: "granted",
    });
  }, []);

  return (
    <Consent
      disableStyles
      location="bottom"
      buttonText="I Understand"
      contentClasses="text-sm mb-6 md:text-base md:mb-0 md:pr-6"
      buttonClasses="btn btn--medium btn--primary whitespace-nowrap"
      containerClasses="fixed inset-0 top-auto z-[100] py-4 px-6 bg-clhbid-gray md:flex"
    >
      We use cookies and other technologies to improve your browsing experience
      on our website, to show you personalized content, to analyze our website
      traffic, and to understand where our visitors are coming from. By browsing
      our website, you consent to our use of cookies and other tracking
      technologies.{" "}
      <a
        href="https://www.cookiesandyou.com"
        className="underline"
        target="_blank"
        rel="noreferrer"
      >
        Learn more
      </a>
    </Consent>
  );
};

export default CookieConsent;
