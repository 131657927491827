import { useRef, useState, useEffect } from "react";
import { graphql, useStaticQuery } from "gatsby";
import algoliasearch from "algoliasearch";
import * as Sentry from "@sentry/react";

import config from "../../clhbid-config";

import { getFeatures } from "../helpers/mapbox";
import type { IndexSalesNode } from "../helpers/algolia-queries";
import generateIndexName from "../helpers/generate-index-name";

export type LocationSuggestion = {
  type: "location";
  key: string;
  label: string;
  coordinates: [number, number];
};

export type SaleSuggestion = {
  type: "sale";
  key: string;
  slug: string;
  name: string;
  location: string;
};

const client = algoliasearch(config.ALGOLIA_APP_ID, config.ALGOLIA_SEARCH_KEY);

const index = client.initIndex(generateIndexName("sales", "suggestions"));

const defaultLocations = [
  {
    type: "location",
    key: "place.21899303",
    label: "Edmonton, AB",
    coordinates: [-113.507996, 53.535411],
  },
  {
    type: "location",
    key: "place.28518439",
    label: "Grande Prairie, AB",
    coordinates: [-118.794987, 55.17108],
  },
] as Array<LocationSuggestion>;

const useSearchSuggestions = (
  query: string,
  types: { locations: boolean; sales: boolean }
): {
  status: "loading" | "error" | "success";
  locations: Array<LocationSuggestion>;
  sales: Array<SaleSuggestion>;
} => {
  const storedQuery = useRef(query);
  const timeout = useRef<NodeJS.Timeout>(undefined);
  const data = useStaticQuery<Queries.UseSearchSuggestionsQuery>(staticQuery);

  useEffect(() => {
    storedQuery.current = query;
  }, [query]);

  const defaultSales = data.sales.nodes.map((sale) => ({
    type: "sale",
    key: sale.slug,
    slug: sale.slug,
    name: sale.name,
    location: sale.location,
  })) as Array<SaleSuggestion>;

  const [search, setSearch] = useState<{
    status: "loading" | "error" | "success";
    locations: Array<LocationSuggestion>;
    sales: Array<SaleSuggestion>;
  }>({
    status: "loading",
    locations: undefined,
    sales: undefined,
  });

  useEffect(() => {
    if (timeout.current) clearTimeout(timeout.current);

    if (query === "") {
      setSearch({
        status: "success",
        locations: undefined,
        sales: undefined,
      });
    } else {
      timeout.current = setTimeout(async () => {
        try {
          const promises = {} as {
            locations?: Promise<Array<LocationSuggestion>>;
            sales?: Promise<Array<SaleSuggestion>>;
          };

          if (types.locations) {
            promises.locations = getFeatures(query, {
              country: "CA",
              limit: 3,
              types: "place",
            }).then((features) => {
              return features.map(
                (feature) =>
                  ({
                    type: "location",
                    key: feature.id,
                    label: `${feature.text}, ${
                      feature.context
                        .find((context) => context.id.startsWith("region"))
                        .short_code.split("-")[1]
                    }`,
                    coordinates: feature.center,
                  }) as LocationSuggestion
              );
            });
          }

          if (types.sales) {
            promises.sales = index
              .search<IndexSalesNode>(query, {
                attributesToRetrieve: ["slug", "name", "location"],
                hitsPerPage: 3,
              })
              .then(({ hits }) => {
                return hits.map((sale) => ({
                  type: "sale",
                  key: sale.slug,
                  slug: sale.slug,
                  name: sale.name,
                  location: sale.location,
                }));
              });
          }

          await Promise.all(Object.values(promises));

          // Because this is async we need to check if the default values should be returned and not override them
          if (storedQuery.current !== "") {
            setSearch({
              status: "success",
              locations: types.locations ? await promises.locations : undefined,
              sales: types.sales ? await promises.sales : undefined,
            });
          }
        } catch (err) {
          Sentry.captureException(err);
          console.error(err);
          setSearch({
            status: "error",
            locations: undefined,
            sales: undefined,
          });
        }
      }, 200);
    }
  }, [query, types.locations, types.sales]);

  return {
    status: search.status,
    locations:
      types.locations && search.status !== "error"
        ? search.locations || defaultLocations
        : undefined,
    sales:
      types.sales && search.status !== "error"
        ? search.sales || defaultSales
        : undefined,
  };
};

const staticQuery = graphql`
  query UseSearchSuggestions {
    sales: allContentfulSale(
      sort: { parcels: { startTime: ASC } }
      filter: { saleStatus: { ne: "completed" } }
      limit: 2
    ) {
      nodes {
        slug
        name
        location
      }
    }
  }
`;

export default useSearchSuggestions;
