import { useStaticQuery, graphql } from "gatsby";
import { useQuery } from "@tanstack/react-query";
import { toast } from "react-toastify";
import axios from "axios";

import { useEffect } from "react";

const QUERY_KEY = ["sale-statuses"];
const TOAST_ID = "sales-toast";
const REFRESH_INTERVAL = 15000;
const STALE_TIME = 60000;

/**
 * The static and the live query must match in order for the live query data to be
 * able to replace the static query data when the live query has been fetched.
 */

const staticQuery = graphql`
  query UseSaleStatuses {
    sales: allContentfulSale {
      nodes {
        slug
        saleStatus
      }
    }
  }
`;

export async function getSaleStatuses(): Promise<
  Array<{
    slug: string;
    saleStatus: string;
  }>
> {
  const {
    data: { data },
  } = await axios({
    method: "get",
    url: `/api/sale-statuses`,
  });

  return data;
}

const useSaleStatuses = (): Record<
  string,
  "upcoming" | "live" | "completed"
> => {
  const {
    sales: { nodes: initialSales },
  } = useStaticQuery<Queries.UseSaleStatusesQuery>(staticQuery);

  const {
    status,
    isStale,
    data: querySales = [],
  } = useQuery<
    ReadonlyArray<{
      slug: string;
      saleStatus: string;
    }>
  >(QUERY_KEY, getSaleStatuses, {
    networkMode: "always",
    staleTime: STALE_TIME,
    refetchInterval: REFRESH_INTERVAL,
  });

  useEffect(() => {
    if (status === "error") {
      toast(
        "An error occurred. Check your internet connection and reload the page.",
        {
          toastId: TOAST_ID,
          autoClose: false,
          type: "error",
        }
      );
    } else if (status !== "loading" && isStale) {
      toast("Sale data is out-of-date. Updating...", {
        toastId: TOAST_ID,
        autoClose: false,
        type: "info",
      });
    } else if (toast.isActive(TOAST_ID)) {
      toast.dismiss(TOAST_ID);
    }
  });

  /**
   * We always need to add the initial sales so that sales present in the gatsby data
   * layer but not present in the query sales (ie. deleted) still have a status until
   * the next reload to avoid breaking the page
   */
  return Object.fromEntries([
    ...initialSales.map((sale) => [sale.slug, sale.saleStatus]),
    ...querySales.map((sale) => [sale.slug, sale.saleStatus]),
  ]) as Record<string, "upcoming" | "live" | "completed">;
};

export default useSaleStatuses;
