import React from "react";
import { MdSearch, MdOutlineLocationOn } from "react-icons/md";
import { PiGavelFill } from "react-icons/pi";

export function getSuggestionGroups({ locations, sales, value, types }) {
  return [
    {
      type: "locations",
      name: value === "" ? "Popular Locations" : "Locations",
      Icon: MdOutlineLocationOn,
      options:
        locations?.map((location) => ({
          type: "location",
          ...location,
          text: (
            <span>
              Sales near <b>{location.label}</b>
            </span>
          ),
        })) || [],
    },
    {
      type: "sales",
      name: value === "" ? "Upcoming Sales" : "Sales",
      Icon: PiGavelFill,
      options:
        sales?.map((sale) => ({
          type: "sale",
          ...sale,
          text: (
            <span>
              Go to <b>{sale.name}</b> - {sale.location}
            </span>
          ),
        })) || [],
    },
    {
      type: "queries",
      Icon: MdSearch,
      options: types.query
        ? [
            {
              type: "query",
              key: "query",
              value: value === "" ? "Terms and conditions" : value,
              text: (
                <span>
                  Search all pages for &quot;
                  <b>{value === "" ? "Terms and conditions" : value}</b>&quot;
                </span>
              ),
            },
          ]
        : [],
    },
  ];
}
