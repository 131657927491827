import React, { useRef, useState, useEffect } from "react";
import { MdClose } from "react-icons/md";

import Button from "./Button";
import MiniContactForm from "./MiniContactForm";

const GlobalContactForm: React.FC = () => {
  const wrapper = useRef<HTMLDivElement>();
  const [open, setOpen] = useState(false);

  useEffect(() => {
    const handleClick = (event) => {
      if (open && !wrapper.current.contains(event.target)) {
        setOpen(false);
      }
    };

    window.addEventListener("click", handleClick);

    return () => {
      window.removeEventListener("click", handleClick);
    };
  }, [open]);

  return (
    <div ref={wrapper}>
      <div
        className={[
          "fixed z-50 right-0 [transition-property:visibility,transform] duration-400 bottom-[calc(50%-64px)]",
          !open
            ? "visible translate-x-0 lg:translate-x-0 lg:opacity-100"
            : "invisible translate-x-full lg:translate-x-0 lg:opacity-0",
        ].join(" ")}
      >
        <Button
          family="primary"
          onClick={() => setOpen(true)}
          className="rounded-r-none py-2 px-[6px] shadow-[0px_4px_16px_0_rgba(0,0,0,0.6)]"
        >
          <span className="block w-5 [writing-mode:vertical-lr]">
            Contact Us
          </span>
        </Button>
      </div>
      <div
        className={[
          "fixed z-50 bottom-0 right-0 w-[472px] ml-auto max-w-full p-4 [transition-property:visibility,transform] duration-300",
          open ? "visible animate-slide-in" : "invisible animate-slide-out",
        ].join(" ")}
      >
        <div className="bg-clhbid-gray-dark shadow-[0px_4px_16px_0_rgba(0,0,0,0.6)] p-4 rounded-md max-h-[calc(100vh-32px)] overflow-y-auto">
          <h3 className="relative text-2xl font-semibold text-center mb-3">
            Contact CLHbid.com
            <button
              onClick={() => setOpen(false)}
              className="absolute w-6 h-6 right-0"
            >
              <MdClose />
            </button>
          </h3>
          <div className="overflow-y-auto">
            <p className="text-center">
              Note that we may take up to a few hours to respond by email. If
              you require immediate assistance call us at{" "}
              <a
                className="text-clhbid-orange hover:text-clhbid-orange-dark"
                href="tel:1-866-263-7480"
              >
                +1 (866) 263-7480
              </a>
              .
            </p>
          </div>
          <MiniContactForm />
        </div>
      </div>
    </div>
  );
};

export default GlobalContactForm;
