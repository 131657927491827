import React from "react";

import type { NestedRendererProps, RendererProps, Timeline } from "./types";

const preferDefault = (m) => (m && m.default) || m;

export function createElement(props: RendererProps) {
  const pageComponentProps = {
    ...props,
    params: {
      ...(props.pageResources.json.pageContext.__params as Record<
        string,
        unknown
      >),
    },
  };

  return React.createElement(preferDefault(props.pageResources.component), {
    ...pageComponentProps,
    key: props.path || props.pageResources.page.path,
  });
}

export function navigateToBackground(
  timeline: Timeline,
  background: NestedRendererProps,
  history: History
): void {
  // Find the offest of the background page
  const offset = timeline
    .slice()
    .reverse()
    .findIndex((page) => page.location.key === background.location.key);

  // Go back that many items inthe history to return to the background page
  history.go(-offset);
}
