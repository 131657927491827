/**
 * isHoverSupported - Returns whether hover is supported on the device or not
 * so that we can use that logic to determine what JS functionality should occur
 */
export function isHoverSupported(): boolean {
  return window.matchMedia("(hover: hover)").matches;
}

/**
 * isMobileDevice - Returns whether the screen size is less than the
 * large width as defined by tailwind (1024px) that allows us to change
 * logic for different devices
 */
export function isMobileDevice(): boolean {
  return window.innerWidth < 1024;
}
