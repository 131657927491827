module.exports = [{
      plugin: require('../plugins/custom-plugin-modal-routing/gatsby-browser.ts'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-posthog/gatsby-browser.js'),
      options: {"plugins":[],"apiKey":"LcHFyjWerW26khwov7MiSa1WPpgYUz7TPl2DAAicpQo","head":true,"isEnabledDevMode":false,"initOptions":{"person_profiles":false}},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["G-9QTTSRFB4K"],"pluginConfig":{"head":true,"exclude":[],"respectDNT":false,"origin":"https://www.googletagmanager.com","delayOnRouteUpdate":0},"gtagConfig":{}},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-NSRKGCW","includeInDevelopment":false,"defaultDataLayer":{"type":"object","value":{"platform":"gatsby"}},"routeChangeEventName":"gatsby-route-change","enableWebVitalsTracking":false,"selfHostedOrigin":"https://www.googletagmanager.com","selfHostedPath":"gtm.js"},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"CLHbid.com","short_name":"CLHbid","start_url":"/","background_color":"#fff","display":"minimal-ui","icon":"/opt/build/repo/src/images/clhbid-favicon.svg","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"d62d28b35c12b44da90c1a0b70be719d"},
    },{
      plugin: require('../node_modules/@sentry/gatsby/gatsby-browser.js'),
      options: {"plugins":[],"dsn":"https://d70afab9ce4344e2978154dc8b50c067@o463899.ingest.sentry.io/5469493","environment":"production","sampleRate":"1.0","tracesSampleRate":0.1,"allowUrls":["www.clhbid.com"]},
    },{
      plugin: require('../gatsby-browser.ts'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
