/**
 * CLHbid Config
 *
 * The purpose of this file is to allow us to create robust configuration objects in
 * a single place based upon the environment variables. Consult the .env.sample file
 * for information regarding what environment variables need to be set depending on
 * the environment the code is running in.
 *
 * Note: Environment variables that begin with the GATSBY_ prefix are available on both
 * the server and the client, while those without are only available server-side.
 */

function parse(value) {
  try {
    return JSON.parse(value);
  } catch {
    return undefined;
  }
}

const config = {
  /** The key used to publish search data to Algolia */
  ALGOLIA_ADMIN_KEY: process.env.ALGOLIA_ADMIN_KEY,
  /** The ID of the Algolia app to publish and retrieve data from */
  ALGOLIA_APP_ID: process.env.GATSBY_ALGOLIA_APP_ID,
  /** The key used to retrieve search results from Algolia client-side */
  ALGOLIA_SEARCH_KEY: process.env.GATSBY_ALGOLIA_SEARCH_KEY,
  /** The token used to authenticate requests to the API */
  API_AUTH_TOKEN: process.env.API_AUTH_TOKEN,
  /** The base url to use when referencing the auction engine */
  AUCTION_ENGINE_URL: process.env.GATSBY_BASE_AUCTION_ENGINE_URL,
  /** The token used to authenticate to the Auction Engine */
  AUCTION_ENGINE_TOKEN: process.env.AUCTION_ENGINE_TOKEN,
  /** The token used to access Contentful CMS content (sales, etc) */
  CONTENTFUL_ACCESS_TOKEN: process.env.CONTENTFUL_ACCESS_TOKEN,
  /** The token used to access the Contentful Content Management API */
  CONTENTFUL_MANAGEMENT_TOKEN: process.env.CONTENTFUL_MANAGEMENT_TOKEN,
  /** The space ID for the Contentful site */
  CONTENTFUL_SPACE_ID: process.env.CONTENTFUL_SPACE_ID,
  /** The environment to use for the Contentful site (default is "development", but when working with environments, your access token has to have access to your desired environment and the master environment. See: https://github.com/gatsbyjs/gatsby/tree/gatsby-source-contentful%407.23.0/packages/gatsby-source-contentful#restrictions-and-limitations) */
  CONTENTFUL_ENVIRONMENT: process.env.GATSBY_CONTENTFUL_ENVIRONMENT,
  /** Determines the environment the application runs in */
  GATSBY_ENV: process.env.GATSBY_ENV,
  /** The ID of the container for google analytics 4 */
  GOOGLE_ANALYTICS_4_ID: process.env.GOOGLE_ANALYTICS_4_ID,
  /** The ID of the Google Tag Manager container */
  GOOGLE_TAG_MANAGER_ID: process.env.GOOGLE_TAG_MANAGER_ID,
  /** The public access token for Mapbox */
  MAPBOX_ACCESS_TOKEN: process.env.GATSBY_MAPBOX_ACCESS_TOKEN,
  /** Indicates whether to mock the authentication layer */
  MOCK_AUTHENTICATION: parse(process.env.GATSBY_MOCK_AUTHENTICATION),
  /** The ID of the hotjar account */
  HOTJAR_ID: process.env.HOTJAR_ID,
  /** The version of the Hotjar snippet to install */
  HOTJAR_SNIPPET_VERSION: process.env.HOTJAR_SNIPPET_VERSION,
  /** The API key that allows us to send analytics data to Posthog */
  POSTHOG_KEY: process.env.POSTHOG_KEY,
  /** The production URL, used for building the sitemap, robots, metadata, etc. */
  PRODUCTION_SITE_URL: "https://www.clhbid.com",
  /** The base URL to use when constructing absolute links to the sales site */
  SALES_URL: process.env.GATSBY_BASE_SALES_URL || "http://localhost:8000",
  /** A comma-seperated list of emails to notify when the webhook updates a sale */
  SALE_UPDATE_EMAILS: process.env.SALE_UPDATE_EMAILS,
  /** The url that we want sentry to send all error reporting to */
  SENTRY_DSN: process.env.SENTRY_DSN,
  /** The percentage we want to sample users for Sentry error reporting */
  SENTRY_SAMPLE_RATE: process.env.SENTRY_SAMPLE_RATE,
  /** SMTP host name */
  SMTP_HOST: process.env.SMTP_HOST,
  /** SMTP username */
  SMTP_USER: process.env.SMTP_USER,
  /** SMTP password */
  SMTP_PASSWORD: process.env.SMTP_PASSWORD,
};

// This object defines the options used to validate whether the exported config is valid
export const options = {
  environments: ["development", "staging", "production"],
  exclude: {
    development: ["HOTJAR_ID", "HOTJAR_SNIPPET_VERSION", "POSTHOG_KEY"],
    staging: [
      "GOOGLE_ANALYTICS_4_ID",
      "GOOGLE_TAG_MANAGER_ID",
      "HOTJAR_ID",
      "HOTJAR_SNIPPET_VERSION",
      "POSTHOG_KEY",
    ],
    production: [""],
  },
  optional: {
    development: [
      "GOOGLE_ANALYTICS_4_ID",
      "GOOGLE_TAG_MANAGER_ID",
      "SALE_UPDATE_EMAILS",
      "SENTRY_DSN",
      "SENTRY_SAMPLE_RATE",
    ],
    staging: ["SENTRY_DSN", "SENTRY_SAMPLE_RATE"],
    production: [],
  },
};

export default config;
