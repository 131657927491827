import React, { useRef, useState, useEffect } from "react";
import { Link } from "gatsby";
import useRouteChange from "../../hooks/use-route-change";

import { useAuth } from "../../concerns/auth";

import Button from "../Button";

import Banner from "./Banner";
import MainMenu from "./MainMenu";
import UserMenu from "./UserMenu";
import SearchBar from "./SearchBar";

import logo from "../../images/clhbid-logo.png";

const Header: React.FC = () => {
  const header = useRef<HTMLDivElement>(null);
  const { state, register, login } = useAuth();
  const [menuOpen, setMenuOpen] = useState<string | undefined>(undefined);

  useEffect(() => {
    // prevent scrollbar on body when mobile menu is openmenu
    document.body.classList.toggle("overflow-hidden", Boolean(menuOpen));
    document.body.classList.toggle("lg:overflow-auto", Boolean(menuOpen));
  }, [menuOpen]);

  useRouteChange(() => {
    setMenuOpen(undefined);
  });

  const createSetOpenMenu = (menu: "user-menu" | "main-menu") => {
    return (value: boolean) => {
      if (value === true) {
        setMenuOpen(menu);
      } else if (value === false && menuOpen === menu) {
        setMenuOpen(undefined);
      }
    };
  };

  return (
    <header
      ref={header}
      className="fixed z-50 top-0 left-0 right-0 bg-clhbid-gray"
    >
      <Banner />
      <nav className="bg-clhbid-gray relative z-[1] grid w-full mx-auto px-4 py-[10px] grid-cols-[1fr_auto_auto] lg:leading-none lg:py-4 lg:grid-cols-[auto_1fr_auto_auto] xl:max-w-[1200px]">
        <div className="relative">
          <Link to="/" className="block lg:mr-12">
            <img
              src={logo}
              alt="CLHbid.com logo"
              className="h-10 py-1 lg:h-[44px]"
            />
          </Link>
        </div>
        <div className="flex order-1 lg:mr-6 lg:relative lg:order-none">
          <SearchBar />
          <MainMenu
            menuOpen={menuOpen === "main-menu"}
            setMenuOpen={createSetOpenMenu("main-menu")}
          />
        </div>
        {state === "authenticated" ? (
          <UserMenu
            menuOpen={menuOpen === "user-menu"}
            setMenuOpen={createSetOpenMenu("user-menu")}
          />
        ) : (
          <div className="hidden gap-6 lg:flex lg:items-center lg:order-2 lg:border-l lg:border-gray-300">
            <button
              onClick={login}
              className="block font-semibold w-fit text-base leading-none py-0 ml-8 hover:underline"
            >
              Login
            </button>
            <Button
              size="medium"
              onClick={register}
              className="w-full whitespace-nowrap py-2"
              data-testid="register-button"
            >
              Create Account
            </Button>
          </div>
        )}
      </nav>
    </header>
  );
};

export default Header;
