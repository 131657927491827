import axios from "axios";
import config from "../../clhbid-config";

export interface MapboxFeatureCollection
  extends GeoJSON.FeatureCollection<GeoJSON.Point> {
  features: MapboxFeature[];
  attribution: string;
  query: string[];
}

export interface MapboxFeature extends GeoJSON.Feature<GeoJSON.Point> {
  id: string;
  bbox?: GeoJSON.BBox;
  center: [number, number];
  context: Array<{
    id: string;
    mapbox_id: string;
    wikidata: string;
    short_code: string;
    text: string;
  }>;
  place_name: string;
  place_type: string[];
  properties: {
    mapbox_id: string;
    wikidata: string;
  };
  relevance: number;
  text: string;
}

type MapboxGeocodingOptions = Partial<{
  autocomplete: boolean;
  bbox: number;
  country: string;
  fuzzyMatch: boolean;
  language: string;
  limit: number;
  proximity: string;
  routing: boolean;
  types: string;
  worldview: string;
}>;

const PLACES_ENDPOINT = "https://api.mapbox.com/geocoding/v5/mapbox.places";

export async function getFeatures(
  query: string,
  options: MapboxGeocodingOptions
): Promise<MapboxFeature[]> {
  const params = new URLSearchParams({
    access_token: config.MAPBOX_ACCESS_TOKEN,
    // Convert options into strings for search params
    ...Object.fromEntries(
      Object.entries(options).map(([key, value]) => [key, String(value)])
    ),
  });

  const { data } = await axios.get<MapboxFeatureCollection>(
    `${PLACES_ENDPOINT}/${encodeURIComponent(query)}.json?${params}`
  );

  return data.features;
}
