import React from "react";
import { Link, graphql } from "gatsby";
import { DateTime } from "luxon";
import { getStartTime, getEndTime } from "../helpers/sale";
import useSaleStatuses from "../hooks/use-sale-statuses";

const objectPropertyMap = {
  sale: {
    sale: (object) => object,
    parcel: (object: Queries.SaleDateSaleFragment) =>
      object.parcels.find((parcel) => !parcel.enBloc),
  },
  parcel: {
    sale: (object: Queries.SaleDateParcelFragment) => object.sale[0],
    parcel: (object) => object,
  },
  option: {
    sale: (object) => object.sale[0],
    parcel: (object) => object,
  },
  feature: {
    sale: (object: Queries.SaleDateFeatureFragment) =>
      object.parcel.find((parcel) => !parcel.enBloc).sale[0],
    parcel: (object: Queries.SaleDateFeatureFragment) =>
      object.parcel.find((parcel) => !parcel.enBloc),
  },
} as Record<
  "sale" | "parcel" | "option" | "feature",
  {
    sale: (object) => Queries.SaleDateSaleFragment;
    parcel: (object) => Queries.SaleDateParcelFragment;
  }
>;

function getPropsType(props) {
  if ("sale" in props) return "sale";
  if ("parcel" in props) return "parcel";
  if ("option" in props) return "option";
  if ("feature" in props) return "feature";
}

const SaleDate: React.FC<
  {
    showLive?: boolean;
    className?: string;
    showPrefix?: boolean;
    liveClassName?: string;
    dateClassName?: string;
    prefixClassName?: string;
    subtextClassName?: string;
  } & (
    | {
        type: "short" | "long";
        sale: Queries.SaleDateSaleFragment;
      }
    | {
        type: "short" | "long";
        parcel: Queries.SaleDateParcelFragment;
      }
    | {
        type: undefined;
        option: Queries.SaleDateOptionFragment;
      }
    | {
        type: "short" | "long";
        feature: Queries.SaleDateFeatureFragment;
      }
  )
> = (props) => {
  const {
    type,
    showLive = false,
    showPrefix = true,
    className = "",
    liveClassName = "",
    dateClassName = "",
    prefixClassName = "",
    subtextClassName = "",
  } = props;

  const objectType = getPropsType(props);

  const object = props[objectType];
  const sale = objectPropertyMap[objectType].sale(object);

  const { [sale.slug]: saleStatus } = useSaleStatuses();

  if (objectType === "option") {
    const exerciseDate = DateTime.fromISO(object.exerciseDate);

    return (
      <p className={className}>
        <span className={prefixClassName}>Exercise</span>
        <span className={subtextClassName}> by </span>
        <span className={dateClassName}>
          {exerciseDate.toFormat("MMMM d',' yyyy")}
        </span>
        <br className="md:hidden" />
        <span className={subtextClassName}> at </span>
        <span className={dateClassName}>{exerciseDate.toFormat("h:mm")}</span>
        <span className={subtextClassName}>
          {exerciseDate.toFormat(" a ZZZZ")}
        </span>
      </p>
    );
  }

  const parcel = objectPropertyMap[objectType].parcel(object);

  const startTime = DateTime.fromISO(
    objectType === "sale" ? getStartTime(sale) : parcel.startTime
  );

  const endTime = DateTime.fromISO(
    objectType === "sale" ? getEndTime(sale) : parcel.endTime
  );

  if (saleStatus === "live" && showLive) {
    return (
      <p className={className} data-testid="sale-date">
        <span className="inline-flex items-center">
          <span className="flex h-3 w-3 relative ml-[2px] mr-2">
            <span className="animate-live-ping absolute inline-flex h-3 w-3 rounded-full bg-gray-700 opacity-75"></span>
            <span className="animate-live-dot relative inline-flex rounded-full h-3 w-3 bg-gray-700"></span>
          </span>
          <span className={liveClassName}>SELLING NOW!</span>
        </span>
      </p>
    );
  }

  if (type === "short") {
    return (
      <p className={className} data-testid="sale-date">
        <span className={dateClassName}>
          {startTime.toFormat("MMM d',' yyyy")}
        </span>
        <span className={subtextClassName}> at </span>
        <span className={dateClassName}>{startTime.toFormat("h:mm")}</span>
        <span className={subtextClassName}>
          {" "}
          {startTime.toFormat("a ZZZZ")}
        </span>
      </p>
    );
  }

  if (type === "long") {
    return (
      <div className={className}>
        <p className="m-0" data-testid="sale-date">
          {showPrefix && (
            <>
              <span className={prefixClassName}>
                {saleStatus === "completed" ? "Sold" : "Selling"}
              </span>
              <span className={subtextClassName}> on </span>
            </>
          )}
          <span className={dateClassName}>
            {startTime.toFormat("MMMM d',' yyyy")}
          </span>
          {!parcel.enBloc && (
            <>
              {showPrefix && <br className="md:hidden" />}
              <span className={subtextClassName}> from </span>
              <span className={dateClassName}>
                {startTime.toFormat("h:mm")}
              </span>
              <span className={subtextClassName}>
                {` ${startTime.toFormat("a")} to `}
              </span>
              <span className={dateClassName}>{endTime.toFormat("h:mm")}</span>
              <span className={subtextClassName}>
                {` ${endTime.toFormat("a ZZZZ")}`}
              </span>
            </>
          )}
        </p>
        {parcel.enBloc && saleStatus !== "completed" && (
          <p
            className="text-clhbid-silver mb-0 mt-2"
            data-testid="enbloc-description"
          >
            {`En Bloc starts 15 minutes after normal bidding closes for eligible bidders. `}
            <Link
              to="/how-clhbid-works/bidding-on-en-bloc-properties-on-clhbid"
              className="text-clhbid-orange font-medium whitespace-nowrap"
            >
              Learn More.
            </Link>
          </p>
        )}
      </div>
    );
  }
};

export const SaleDateFragment = graphql`
  fragment SaleDateSale on ContentfulSale {
    slug
    parcels {
      startTime
      endTime
      enBloc
    }
  }

  fragment SaleDateParcel on ContentfulParcel {
    startTime
    endTime
    enBloc
    sale {
      slug
    }
  }

  fragment SaleDateOption on ContentfulOption {
    exerciseDate
    sale {
      slug
    }
  }

  fragment SaleDateFeature on ContentfulFeature {
    parcel {
      startTime
      endTime
      enBloc
      sale {
        slug
      }
    }
  }
`;

export default SaleDate;
