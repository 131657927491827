import React from "react";
import { FaYoutube, FaInstagram, FaFacebookF } from "react-icons/fa";
import { HiPhone, HiMail } from "react-icons/hi";

const socialIcons = [
  {
    platform: "Facebook",
    icon: FaFacebookF,
    href: "https://www.facebook.com/CLHbid/",
  },
  {
    platform: "Instagram",
    icon: FaInstagram,
    href: "https://www.instagram.com/clhbid/",
  },
  {
    platform: "Youtube",
    icon: FaYoutube,
    href: "https://www.youtube.com/channel/UCpcbKTbtSSFmbH26Qtvn_WQ",
  },
];

const Banner: React.FC = () => (
  <div className="bg-clhbid-charcoal">
    <div className="flex justify-between mx-auto px-4 py-[6px] lg:justify-end lg:gap-6 xl:max-w-[1200px]">
      <div className="flex gap-3 lg:order-1">
        {socialIcons.map(({ platform, icon: Icon, href }) => (
          <a key={platform} href={href} aria-label={`Follow us on ${platform}`}>
            <Icon className="w-4 h-4 text-white hover:text-clhbid-orange" />
          </a>
        ))}
      </div>
      <a
        href="tel: +1 (866) 263-7480"
        className="flex items-center text-sm text-white hover:text-clhbid-orange"
      >
        <HiPhone className="w-4 h-4 p-[1px] mr-1" />
        <span className="leading-none pb-[1px]">+1 (866) 263-7480</span>
      </a>
      <a
        href="mailto:info@clhbid.com"
        className="hidden items-center text-sm text-white hover:text-clhbid-orange lg:flex"
      >
        <HiMail className="w-4 h-4 p-[1px] mr-1" />
        <span className="leading-none pb-[1px]">info@clhbid.com</span>
      </a>
    </div>
  </div>
);

export default Banner;
