const compactFormatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
  notation: "compact",
  minimumFractionDigits: 0,
});

const standardFormatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
  useGrouping: true,
  notation: "standard",
  minimumFractionDigits: 0,
  maximumFractionDigits: 0,
});

export default function formatCurrency(
  value: number,
  notation: "compact" | "standard"
): string {
  if (notation === "compact") {
    return compactFormatter.format(Math.round(value / 1000) * 1000);
  } else if (notation === "standard") {
    return standardFormatter.format(value);
  }
}
