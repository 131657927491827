import React from "react";
import { graphql, useStaticQuery } from "gatsby";

import routes from "../../clhbid-routes";

import useSaleStatuses from "../hooks/use-sale-statuses";

import Button from "./Button";

const SaleBanner: React.FC = () => {
  const data = useStaticQuery<Queries.SaleBannerQuery>(query);
  const statuses = useSaleStatuses();

  const liveSales = data.sales.nodes.filter(
    (sale) => statuses[sale.slug] === "live"
  );

  if (liveSales.length === 0) {
    return null;
  }

  return (
    <div className="fixed z-[49] flex items-center space-x-8 px-4 py-3 bg-clhbid-gray bottom-0 right-0 rounded-tl-xl shadow-[0_0_8px_0_rgba(0,0,0,0.4)] w-full max-w-full sm:w-fit">
      <div className="flex-1 min-w-0">
        <h3 className="font-bold text-white text-xl mb-1 truncate">
          {liveSales.length === 1
            ? liveSales[0].name
            : `${liveSales.length} Sales`}
        </h3>
        <div data-testid="indicator" className="flex items-center">
          <span className="flex h-3 w-3 relative mr-[6px]">
            <span className="animate-live-ping absolute inline-flex h-3 w-3 rounded-full bg-clhbid-gray opacity-75"></span>
            <span className="animate-live-dot relative inline-flex rounded-full h-3 w-3 bg-clhbid-gray"></span>
          </span>
          <span className="block leading-4 text-clhbid-orange font-bold italic">
            SELLING NOW!
          </span>
        </div>
      </div>
      <Button
        href={
          liveSales.length === 1 ? routes.sale.bidding(liveSales[0]) : "/sales"
        }
        size="medium"
        external
      >
        {liveSales.length === 1 ? "View Bidding" : "View Sales"}
      </Button>
    </div>
  );
};

const query = graphql`
  query SaleBanner {
    sales: allContentfulSale {
      nodes {
        slug
        name
      }
    }
  }
`;

export default SaleBanner;
