import config from "../../clhbid-config";

export default function generateIndexName(
  environment: string,
  replica?: string
): string {
  return `${(config.CONTENTFUL_ENVIRONMENT as string).replace(
    /[^a-zA-Z0-9-]/g,
    ""
  )}-${environment}${replica ? `-${replica}` : ""}`;
}
