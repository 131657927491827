import React, { useRef } from "react";
import { GatsbyBrowser } from "gatsby";

import type { RendererProps, Timeline } from "./types";

import { createElement, navigateToBackground } from "./helpers";

import ModalRoutingContext from "./ModalRoutingContext";

/*
 * Renderer - If a new route is being accessed and Gatsby's `location.state` is passed a
 * value of `true` then this component uses the previous routes props to render the page
 * in the background while using the new routes props to render a modal inside of the page.
 */
const Renderer: React.FC<RendererProps> = (props) => {
  const timeline = useRef<Timeline>([props]);

  // If location has changed since the last render add an entry to the timeline
  if (
    timeline.current.length &&
    props.location.pathname !==
      timeline.current[timeline.current.length - 1]?.location.pathname
  ) {
    timeline.current.push(props);
  }
  // Otherwise store the props from the current render for comparison
  else {
    timeline.current[timeline.current.length - 1] = props;
  }

  // Check if the current entry in the timeline has state.modal set to true
  const isModal = Boolean(
    timeline.current.length > 1 &&
      timeline.current[timeline.current.length - 1].location.state?.modal
  );

  // Check if the previous entry in the timeline had state.modal set to true
  const wasModal = Boolean(
    isModal &&
      timeline.current.length > 2 &&
      timeline.current[timeline.current.length - 2].location.state?.modal
  );

  if (!isModal) {
    return createElement(props);
  }

  /**
   * Get the index of the first occurence of the page on the timeline. This allows us to
   * hijack the logic of the back button, because if the back button is used then the key
   * will match a pre-existing entry in the timeline. If that is the case, then we are
   * able to use the page that you navigated back to's props and also determine which page
   * should be rendered in the background.
   */
  const pageIndex = timeline.current.findIndex(
    (history) => history.location.key === props.location.key
  );

  /**
   * Find the last occurence in the timeline before the page index that is not a modal.
   * This allows multiple pages to be rendered as modals conseutively while retaining
   * the same background.
   */
  const background = timeline.current
    .slice(0, pageIndex + 1)
    .reverse()
    .find((history) => !history.location.state?.modal);

  return (
    <>
      {createElement(background)}
      <ModalRoutingContext.Provider
        value={{
          /** true if the current route is a modal */
          isModal,
          /** true if both the previous and current route are modals */
          wasModal,
          handleClose: () =>
            navigateToBackground(timeline.current, background, history),
        }}
      >
        {createElement(timeline.current[pageIndex])}
      </ModalRoutingContext.Provider>
    </>
  );
};

// Returns a component that wraps around a page element in Gatsby and allows for customized rendering
const wrapPageElement: GatsbyBrowser["wrapPageElement"] = ({ props }) => (
  <Renderer {...props} />
);

export default wrapPageElement;
